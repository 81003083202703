<template>
  <div class="page">
    <div class="header">
      <div></div>
      <div><img class="msg-icon" style="width: 28px;height: 28px; " src="@/assets/lang2.png" alt="" @click="changlang()" /></div>
      <!-- <div>
        <img class="close" src="../../assets/img/close.png" alt="" />
      </div> -->
    </div>
    <div class="content">
      <img class="logo" src="../../assets/img/logo3.png" alt="" />
      <van-form>

        <div class="formText" style="background-color: #2B2B2B !important;">
          <!-- <select  v-model="guoma">
            <option :data-iconurl="item.img" style="border: none;border: 1px solid #cccccc;" v-for="(item, index) in list" :key="index">{{item.code}}
            </option>
          </select> -->
          <div style="height: 50px; width:100px;border-radius: 5px; border:0px;background-color:#323233; 
			position: absolute; z-index: 999;left: 30px; top: 7rem; color: #FFFFFF;">
          <fdSelect :list="list" v-model="selected"></fdSelect>
          </div>
          <van-field v-model="username" :label="$t('国码')" :placeholder="$t('输入手机号码')" />
          <hr class="bt" style="background-color: #2B2B2B !important;" />
          <van-field v-model="password" type="password" :label="$t('输入密码')" :placeholder="$t('请输入密码')" />
        </div>
        <div style="margin: 16px; padding: 32px 60px 0">
          <van-button class="btn" round block type="info" @click="onSubmit">{{
            $t('登录')
          }}</van-button>
        </div>
      </van-form>
    </div>
    <ROBOT />
    <div class="signUp">
      <router-link to="/register"> {{ $t('还没有账号点击注册') }}</router-link>
    </div>
    <div class="wel-foot">
      <router-link to="/forgetpass"><span>{{ $t('忘记密码') }}</span></router-link>
    </div>

  </div>

</template>

<script>
import { apiLogin, apigetGuoma } from '@/http/api/' // 导入我们的api接口
import { Button, Form, Field, Toast } from 'vant'
import ROBOT from '@/components/robot'
import fdSelect from "@/components/fd-select/fd-select.vue"
export default {
  components: {

    ROBOT,
    fdSelect

  },
  data () {
    return {
      username: '',
      password: '',
      guoma: "",
      list: [],
      selected: {}, //选中国旗拿到的数据
    }
  },
  created () {
    apigetGuoma({

    }).then((res) => {
      this.list = res.info.map(v=>{
        return {
          ...v,
          img:require(`@/static/images/country/${v.code.replace(/\+/g,'')}.png`),
        }
      });
      this.guoma = this.list[0].code;
      this.selected=this.list[0]
    });
  },
  methods: {
    changlang () {
      this.$router.push('/lang')
    },
    onSubmit () {
      Toast.loading({
        duration: 0,
        message: this.$t('加载中'),
        forbidClick: true
      })
	
      apiLogin({
        mobile: this.username,
        password: this.password,
        optionval: this.selected.code
      }).then((res) => {
        if (res.status == 1) {
          setTimeout(() => {
            // 存储用户登录状态
            localStorage.setItem('key', res.userid)
            localStorage.setItem('msg', 1)
            Toast.clear()
            Toast.success(this.$t('登录成功'))

            this.$router.replace('/')
          }, 1000)
        } else {
          Toast.fail(this.$t(res.info))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  padding: 0 18px 40px;
  .logg {
    font-size: 40px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
  }
}
.van-field {
  background-color: #2b2b2b !important;
}
.van-field__control {
  background-color: #2b2b2b !important;
}
.header {
  padding: 0 10px;
  height: 56px;
  line-height: 56px;
  display: flex;
  justify-content: space-between;
  .close {
    width: 28px;
    height: 28px;
  }
}
.content {
  text-align: center;
  .logo {
    width: 500px;
    height: 300px;
    margin-top: 53px;
    margin-bottom: 80px;
  }
  ::v-deep .van-cell {
    background-color: #171717;
  }
  ::v-deep input.van-field__control {
    color: #fff;
  }
  .formText {
    // opacity: 1
    // margin-top: 20px;
    padding: 10px 21px;
    border: 1px solid rgba(255, 255, 255, 0.20000000298023224);
    border-radius: 20px;
  }
  ::v-deep .van-cell {
    height: 110px;
    line-height: 110px;
  }

  .btn {
    box-shadow: 0px 10px 20px #0d9135;
    font-size: 40px;
    height: 103px;
    background: linear-gradient(1deg, #0d9135, #ffedd8);
    border: none;
    color: #000;
  }
}
.signUp {
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #0d9135;
  margin-top: 90px;
}
.wel-foot {
  //   color: #fff;
  text-align: center;
  // width: 80%;
  // position: absolute;
  // bottom: 90px;
  // left: 50%;
  // margin-left: -40%;
  height: 16px;
  margin-top: 20px;
  font-size: 24px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #0d9135;
}
.bt {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20000000298023224);
}
</style>
