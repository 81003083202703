<template>
  <layout :isShowHeader="false">
    <template #content>
      <div class="content">
        <div class="mybg hide">
          <van-row class="mt20 flexbox center">
            <van-col span="8"></van-col>
            <van-col span="8" class="f40 flexbox center">{{
              $t('我的资产')
            }}</van-col>
            <van-col
              span="8"
              class="flexbox j-end a-center"
             
            >
              <span class="mr32"></span>
            </van-col>
          </van-row>
          <div class="user flex_row_space-between">
            <div class="ml32 flexbox a-center">
              <img
                class="smile-icon"
                src="@/assets/img/logo2.png"
                alt=""
              />
              <div class="f38 ml20">{{ info.mobile }} </div>
            </div>
            <div class="mr32 flexbox a-center">
              <span>{{ $t('邀请码') }} ：{{ info.invit }}</span>
              <div
                class="copy flexbox center ml20 f18"
                @click="copyEvent(info.invit)"
              >
                {{ $t('复制') }}
              </div>
            </div>
          </div>
        </div>
        <div class="purseP">
          <div class="flexbox box">
            <div class="purse mr32 ml32 mr32 flexbox_column">
              <div class="flex_row_space-between mt30" style="width: 100%">
                <div class="ml32">
                  <span class="f46">{{ info.usdt }}</span>
                  <span class="ml10 ccolor">USDT</span>
                </div>
                <div class="mr32">
                  <span class="ccolor"><span style="font-size: 12px;">Mex$:</span></span>
                  <span class="f30 ml10">{{ (info.usdt * info.rate ).toFixed(0)}}</span><br>
				  <span class="ccolor"><span style="font-size: 12px;">Col$:</span></span>
				  <span class="f30 ml10">{{ (info.usdt * 5000 ).toFixed(0)}}</span>
                </div>
              </div>
              <div class="ccolor ml32 mt5">{{ $t('我的钱包余额') }}</div>
              <div class="line mt30"></div>
              <router-link to="/myRobot" class="flex_row_space-between mt34">
                <div class="ml32 flexbox hcenter">
                  <img class="robot" src="@/assets/img/logo.png" alt="" />
                  <div class="ml10">{{ $t('我的机器人仓库') }}</div>
                </div>
                <div class="flexbox hcenter mr32">
                  <span>{{ myaicount }} / {{totalaiCount}}</span>
                  <img class="more ml20" src="@/assets/icon/more.png" alt="" />
                </div>
              </router-link>
            </div>
          </div>
          <div class="mt20 daili ml14 mr14">
            <div class="flex_row_space-between">
              <div class="f30 ml32">{{ $t('代理信息') }}</div>
              <router-link
                to="/drawings"
                class="mr32 btn flexbox center bcolor"
                >{{ $t('去提款') }}</router-link
              >
            </div>
            <div class="cailicard ml32 mr32 flex_row_space-between">
              <div
                class="item flexbox_column box"
                style="justify-content: space-between"
              >
                <div class="flex_row_space-between">
                  <div>{{ $t('个人收益累计') }}</div>
                  <img class="icon" src="@/assets/icon/gerenshouyi.png" alt="" style="width: 20px;height: 20px;" />
                </div>
                <div class="f30 bold">
                  {{ shuju.geren_shouyi }} <span class="ccolor f18">USDT</span>
                </div>
              </div>
              <div
                class="item flexbox_column box"
                style="justify-content: space-between"
              >
                <div class="flex_row_space-between">
                  <div>{{ $t('今日代理收益') }}</div>
                  <img class="icon" src="@/assets/icon/jinridaili.png" alt=""  style="width: 20px;height: 20px;"/>
                </div>
                <div class="f30 bold">
                  {{ shuju.today_daili }} <span class="ccolor f18">USDT</span>
                </div>
              </div>
              <div
                class="item flexbox_column box"
                style="justify-content: space-between"
              >
                <div class="flex_row_space-between">
                  <div>{{ $t('代理累计收益') }}</div>
                  <img class="icon" src="@/assets/icon/leijishouyi.png" alt=""  style="width: 20px;height: 20px;"/>
                </div>
                <div class="f30 bold">
                  {{ shuju.daili_leiji }} <span class="ccolor f18">USDT</span>
                </div>
              </div>
              <div
                class="item flexbox_column box"
                style="justify-content: space-between"
              >
                <div class="flex_row_space-between">
                  <div>{{ $t('机器人返佣') }}</div>
                  <img class="icon" src="@/assets/icon/jiqirenfan.png" alt=""  style="width: 20px;height: 20px;"/>
                </div>
                <div class="f30 bold">
                  {{ shuju.roobtfanyong }} <span class="ccolor f18">USDT</span>
                </div>
              </div>       
            </div>
            <router-link to="/myRobot">
              <div class="num ml32 mr32 flex_row_space-between">
                <div class="ml32 flexbox hcenter">
                  <img class="roboticon" src="@/assets/img/logo2.png" alt=""/>
                  <div class="ml20 bcolor">
                    {{ $t('自动订单机器人数量') }}
                  </div>
                </div>
                <div class="mr32 bcolor f30 bold">{{ myaicount }}</div>
              </div>
            </router-link>
          </div>
          <div class="mt20 nav ml14 mr14">
            <router-link
              class="navItem flex_row_space-between"
              v-for="item in navList"
              :key="item.title"
              :to="item.url"
            >
              <div class="flexbox hcenter ml32">
                <div class="dot"></div>
                <div class="ml20">{{ item.title }}</div>
              </div>
              <div class="mr32">
                <img class="jiantou" src="@/assets/icon/jiantou.png" alt="" style="width: 10px; height: 10px;" />
              </div>
			 
            </router-link>
			
          </div>
        </div>
		<ROBOT />
      </div>
    </template>
  </layout>
</template>

<script>
import {
  apiLoginout,
  apiUser_mas2,
  apiRobots,
  apizuodan,
  apimyshouyi,
  apimyrobots
} from '@/http/api/'
import { Button, Form, Field, Toast } from 'vant'
import ROBOT from '@/components/robot'
export default {
	components: {
	  
		ROBOT,
	
	},
  data() {
	 
    return {
      navList: [
        {
          title: this.$i18n.t('付款方式'),
          url: '/bankCard'
        },
        {
          title: this.$i18n.t('个人收入记录'),
          url: '/personalIncome?active=1'
        },
        {
          title: this.$i18n.t('团队收入记录'),
          url: '/personalIncome'
        },
        {
          title: this.$i18n.t('充值记录'),
          url: '/recharge'
        },
        {
          title: this.$i18n.t('我的团队成员'),
          url: '/teaminfo'
        },
        {
          title: this.$i18n.t('活动中心'),
          url: '/actionCenter'
        },
        {
          title: this.$i18n.t('修改登录密码'),
          url: '/forgetpass'
        },
        {
          title: this.$i18n.t('交易密码管理'),
          url: '/setPwd'
        },
		{
		  title: this.$i18n.t('退出'),
		  url: '/findSuccess'
		}
      ],
      token: '',
      info: '',
      aituring: '',
      shuju: '',
      myaicount: 0,
	  totalaiCount:0
    }
  },
  created() {
    var token = localStorage.getItem('key')
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    Toast.loading({
      duration: 0,
      message: this.$t('加载中'),
      forbidClick: true
    })
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      this.info = res.user
    })

    apimyshouyi({ userid: token }).then((res) => {
      this.shuju = res.info
    })
    apizuodan({ userid: token }).then((res) => {
      this.myaicount = res.info
    })
	apimyrobots({userid: token}).then((res) => {
	 
	  this.totalaiCount = res.info.length;
	})
    Toast.clear()
  },
  methods: {
    onLoginout() {
      apiLoginout({}).then((res) => {
        console.log(res)

        localStorage.removeItem('key')
        //this.$store.commit("SET_TOKEN", null);
        this.$router.replace('/login')
      })
    },
    copyEvent(_data) {
      console.log('fuzhi ', _data)
      this.$copyText(String(_data))
      Toast(this.$t('复制成功'))
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .mybg {
    width: 100%;
    height: 520px;
    background: url('@/assets/icon/mybg.png') no-repeat;
    background-size: 100% 520px;
    .user {
      .smile-icon {
        width: 77px;
        height: 77px;
      }
      .copy {
        width: 87px;
        height: 45px;
        background: #171718;
        border-radius: 23px 23px 23px 23px;
      }
    }
  }
  .purseP {
    position: relative;
    top: -350px;
    .purse {
      border-radius: 27px;
      width: 100%;
      height: 314px;
      background: rgb(77, 77, 77);
    }
    .daili {
      background: rgb(77, 77, 77);
      border-radius: 59px 59px 0px 0px;
      padding: 46px 0;
      .btn {
        width: 165px;
        height: 56px;
        background: #0d9135;
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
      }
      .cailicard {
        flex-wrap: wrap;
        .item {
          margin-top: 20px;
          padding: 30px;
          width: 320px;
          height: 150px;
          background: linear-gradient(157deg, #2a2c2e 8%, #444749 100%);
          box-shadow: 0px 19px 26px 1px rgba(0, 0, 0, 0.5);
          border-radius: 12px 12px 12px 12px;
          .icon {
            width: 29px;
            height: 29px;
          }
        }
      }
      .num {
        background: #0d9135;
        height: 163px;
        border-radius: 28px;
        margin-top: 30px;
        .roboticon {
          width: 79px;
          height: 79px;
        }
      }
    }
    .nav {
      background: rgb(77, 77, 77);
      .navItem {
        height: 110px;
        .dot {
          width: 9px;
          height: 9px;
          background: #0d9135;
          border-radius: 50%;
        }
        .jiantou {
          width: 31px;
          height: 9px;
        }
      }
    }
    .robot {
      width: 76px;
      height: 76px;
    }
    .more {
      width: 9px;
      height: 12px;
    }
  }
}
</style>
