<template>
   <div class="robot2" >
	  
    <img
      @click="show = true"
      class="img"
      src="../../assets/img/kefu.png"
      alt=""
    />

    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" style="height: 50%; text-align: left;color: white;" :description="$t('kefu1')"/>
  </div> 
</template>

<script>
	import {
		  apigetkf
		} from "@/http/api/";
export default {
  data() {
    return {
      show: false,
      actions: [],
	  token:""
    }
  },
   created:function(){
  	  var token = localStorage.getItem("key");
  	  if(token){
  		  this.token = token;
  	  }
  	  
  	  this.init();
    },
  methods: {
    onSelect(item) {
      this.show = false
	
	 if(item.index == 1 || item.index ==2 || item.index == 3 || item.index == 0){
		 this.lianxi2(item.val);
	 }
	 
	 if(item.index == 4){
	 		 this.lianxi(item.val);
	 }
    },
	showkefu(){
		this.show = true; 
		var token = localStorage.getItem("key");
		this.token = token;
		if(token){
			this.daoshi = true;
		}else{
			this.daoshi = false;
		}
		this.init();
	},
	lianxi2(tel){
			 window.location.href = tel;
			 //window.location.href =  "tg:msg?text="+tel;
	},
	lianxi(tel){
			if (window.navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
			       window.location.href = "https://wa.me/"+tel;
			       setTimeout(function () {
			            window.location.href = "https://itunes.apple.com/us/app/whatsapp-messenger/id310633997?mt=8";
			            window.location.href = "https://itunes.apple.com/us/app/whatsapp-messenger/id310633997?mt=8";
			        }, 2000);
			    } else if (window.navigator.userAgent.match(/android/i)) {
			        window.location.href = "https://wa.me/"+tel;;
			        setTimeout(function () {
			            window.location.href = "https://play.google.com/store/apps/details?id=com.whatsapp";
			        }, 2000)
			    }
	},
	init(){
		apigetkf({userid:this.token}).then((res) => {
			let arr = res.info
			if(arr.whatsapp){
				this.actions = [
				
				    { name: this.$t('Plus500ai Grupo de WhatsApp'),val: arr.czkf1, index:1},
					{ name: this.$t('Plus500ai Canal de Telegram'),val: arr.czkf2,index:2 },
					{ name: this.$t('Plus500ai Grupo de Telegram'),val: arr.yykf ,index:3 },
					{ name: this.$t('Plus500ai Servicio de Whatsapp'),val: arr.whatsapp ,index:4 }];
				}else{
					this.actions = [
						
					{ name: this.$t('Plus500ai Grupo de WhatsApp'),val: arr.czkf1, index:1}, 
					{ name: this.$t('Plus500ai Canal de Telegram'),val: arr.czkf2,index:2 },
					{ name: this.$t('Plus500ai Grupo de Telegram'),val: arr.yykf ,index:3 },
					];
			}
			
		}); 
		}
  }
}
</script>

<style lang="scss" scoped>
.robot2 {
  position: fixed;
  bottom: 400px;
  right: 60px;
  .img {
    width: 90px;
    height: 90px;
  }
  .van-action-sheet__description{
	  background-color: #2a2c2e;
  }
  .van-action-sheet__item{
	  text-align: left;
  }
  .van-popup{
	  background-color: #2a2c2e; 
	  
  }
}
</style>
