<template>
  <layout :isShowFooter="false">
    <template #title>{{$t('活动中心')}}</template>
   <template #right></template>
    <template #content>
       <!--<div class="card card-1 flexbox_column ml32 mr32">
        <div class="flexbox_column a-center">
          <img class="robot" src="@/assets/icon/robot.png" alt="" />
          <div class="f30 mt20 mb20">Plus500AI</div>
          <div class="mb20"> Robot quantifies AI trading income</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab1.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab1.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.daily }}</div>
          </div>
        </div>
        <div class="flexbox j-end ccolor f30 mt20">※1USDT=1$</div>
      </div>
      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Points Level Discount Rules</div>
          <div style="line-height: 20px">
            Points :Individuals who purchase rob will get 2 times points,and
            their superiors will get1tin (Applicable to direct subordinate
            relati Subordinate consumption discounts:
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab2.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab2.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          Users with different relationships (direct and indirect)will receive
          discounts for subordinates to buy robots based on the proportion of
          agency level discounts. Other higher-ups will also receive a
          corresponding proportion of discounts according to their own agent
          level. Discounts for purchasing robots: those who have the agent level
          will receive correspondingagent level preferential rights when buying
          robots on their own.
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Direct agent commission description</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab3.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab3.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          Level 1 agent percentage obtained by A:12% Level2 agent percentage
          obtained by A: 6% Level3 agent percentage obtained by A: 3% Examples:
          Daily profit of B,C, D is: 100 USD Level 1 agent reward obtained by A
          :12%*100=12 USD Level 2 agent reward obtained by A: 6%*100=6 USD Level
          3 agent reward obtained by A: 3%*100=3 USD
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Direct agent commission description</div>
          <img class="typeicon" src="@/assets/icon/type.png" alt="" />
        </div>
        <div class="lh20 ccolor mt30">
          A represents you A lnviteB(A's subordinate is B) B Invite C (B's
          subordinate is C) C lnvite D (C's subordinate is D) A can have
          multiple subordinates(B1,B2,B3,B4,B5,B6,etc.) B(B1,B2,B3) may also
          have multiple subordinates(C1,c2,c3,c4,c5,c6,etc.) C... D...
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Direct agent commission description</div>
          <div style="line-height: 20px">
            A represents you A Invite B(A's subordinate is B) B Invite C(B's
            subordinate is C) C lnvite D (C's subordinate is D) △The upper level
            will be based on the total activation amount of the lower
            level.0btain different agent reward ratios(%). AAnd refer to the
            daily income of subordinates to obtain agency rewards according to
            the corresponding proportion. △The robot must be in a normal active
            state. Agent income is provided by Tamer Technology's Al smart
            financial Nuro Al management team, and will not affect the normal
            income of subordinates.
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab4.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab4.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.totals }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          Examples:The total activation amount of the upper level(A)>30% of the
          total activation amount of the lower level(B/C/D):(E.g:A>30% of B/C/D)
        </div>
      </div>-->
	  
	  <br><br>
	   	<div style="padding-left: 20px;"> {{$t('A: Evento de bonificación de depósito personal')}}</div>
	       <div class="item-Div" v-for="(item,index)  in yaoqinglist" v-bind:key="index">
	         <div class="item-div-left" style="">
	           <div style="width: 26px;height: 26px;border-radius: 18px;text-align: center;background-color: seagreen;">
	             <h2 style="color: white;margin-top: 0.1rem;">{{index +1}}</h2>
	           </div>
	         </div>
	         <div class="item-div-center">
	           <div style="display: flex">
	             <h3 style="color: white;">{{item.name}}</h3>
	             <h3 style="color: firebrick;margin-left: 10px;">+{{item.jiangli}} U</h3>
	           </div>
	           <h4 class="item-div-center-tips">{{item.desc}}</h4>
	           <div style="width: 120px;height: 25px;margin-top:10px;background-color: #e07348;border-radius: 5px;justify-content: center;align-items: center">
	               <h3 style="color:firebrick;padding-top: 4px;;margin-left: 20px"><van-count-down :time="item.ct_time" /></h3>
	           </div>
	         </div>
	         <div class="item-div-right" >
	           <div style="display: flex;text-align: center;margin-left: 55px;">
	             <div style="color: firebrick;font-size: 14px;display: flex">{{item.yi}}<div style="color: wheat"> / {{item.need_count}}</div></div>
	           </div>
	           <div v-if="item.lq_st==0" @click="lingqu(item.id)" style="width: 70px;height: 25px;background-color: crimson;border-radius: 15px;margin-top: 50px;display: inline-block;text-align: center">
	               <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('领取')}}</div>
	           
	  		   </div>
	         </div>
	       </div>
		   <div style="padding-left: 20px;"> {{$t('邀请好友注册，领取佣金')}}</div>
		      <div class="item-Div" v-for="(item,index)  in yaoqinglist2" v-bind:key="index">
		        <div class="item-div-left" style="">
		          <div style="width: 26px;height: 26px;border-radius: 18px;text-align: center;background-color: seagreen;">
		            <h2 style="color: white;margin-top: 0.1rem;">{{index +1}}</h2>
		          </div>
		        </div>
		        <div class="item-div-center">
		          <div style="display: flex">
		            <h3 style="color: white;">{{item.name}}</h3>
		            <h3 style="color: firebrick;margin-left: 10px;">+{{item.jiangli}} U</h3>
		          </div>
		          <h4 class="item-div-center-tips">{{item.desc}}</h4>
		          <div style="width: 120px;height: 25px;margin-top:10px;background-color: #e07348;border-radius: 5px;justify-content: center;align-items: center">
		              <h3 style="color:firebrick;padding-top: 4px;;margin-left: 20px"><van-count-down :time="item.ct_time" /></h3>
		          </div>
		        </div>
		        <div class="item-div-right" >
		          <div style="display: flex;text-align: center;margin-left: 55px;">
		            <div style="color: firebrick;font-size: 14px;display: flex">{{item.yi}}<div style="color: wheat"> / {{item.need_count}}</div></div>
		          </div>
		          <div v-if="item.lq_st==0" @click="lingqu(item.id)" style="width: 70px;height: 25px;background-color: crimson;border-radius: 15px;margin-top: 55px;display: inline-block;text-align: center">
		              <div style="color: white;font-size: 14px;margin-top: 3px">{{$t('领取')}}</div>
		          
		   	   </div>
		        </div>
		      </div>
		   <ROBOT />
	   <div class="card card-1 flexbox_column   ">
	  <div class="flexbox_column a-center" style="width: 100%;">
	   
	    <img class="typeicon" :src="info.huodong_img" alt="" />
	  
	  </div>
	  </div>
    </template>
  </layout> 
</template>

<script>
	import ROBOT from '@/components/robot'
	import {
	  apikongtou, apiky_buy,apiUser_mas2,
	  
	} from "@/http/api/";
	import {
	  NavBar,
	  Button,
	  DropdownMenu,
	  DropdownItem,
	  Tab,
	  Tabs,
	  Popup,
	  Field,
	  Slider,
	  Toast,
	} from "vant";
export default {
	components: {
	 
		 ROBOT
	},
  data() {
    return {
		yaoqinglist:[],
		yaoqinglist2:[],
		token:"",
		info:[],
      tab1: {
        title: [
          'Robot\ntype',
          'Robot price',
          'Start-up funds',
          'Total amount',
          'Daily income'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$20',
            total: '$22',
            daily: '$0.3-0.4'
          },
          {
            i: 'NO2',
            price: '$5',
            startUp: '$100',
            total: '$105',
            daily: '$1.5-1.8'
          }
        ]
      },
      tab2: {
        title: [
          'Points \nAccumula \ntion',
          'Agent \n Level',
          'Subodinates\n Consumer\n Rebate',
          'Discount\n For Rob ot \nPurchase'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$20',
            total: '$22'
          },
          {
            i: 'NO2',
            price: '$5',
            startUp: '$100',
            total: '$105'
          }
        ]
      },
      tab3: {
        title: [
          'Agent\nLevel',
          'Agent\n Reward\n Ratio(%)',
          'Subordinate\n Daily \nProfit($)',
          'Agent\n Reward\n ($)'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32'
          },
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32'
          }
        ]
      },
      tab4: {
        title: [
          'Agent\nLevel',
          'Reference0 \nbject',
          'A>30% of\n B/C/D',
          '20% of B/ \n C/D < A≤3 \n 0% of B/C/D',
          'A≤20% of \n B/C/D'
        ],
        data: [
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32',
            totals: '$32'
          },
          {
            i: 'NO1',
            price: '$2',
            startUp: '$30',
            total: '$32',
            totals: '$32'
          }
        ]
      }
    }
  },
  created() {
     var token = localStorage.getItem("key");
     console.log("token", token);
     if (!token) {
       this.$router.replace("/login");
     }
     this.token = token;
     this.init();
	 apiUser_mas2({
	   userid: token
	 }).then((res) => {
	   // 未登录踢下线
	   if (res.status == 10) {
	     this.$router.replace('/login')
	   }
	   // this.showdown = res.user.showdown
	   this.info = res.user
	  
	 })
   },
  methods: {
	  async lingqu(id){
	  	await  apiky_buy({
	  	  userid: this.token,
	  	  id:id
	  	}).then((res) => {
	  		if(res.status == 0){
	  			Toast.fail(this.$t(res.info));
	  		}else{
	  			setTimeout(() => {
	  			  Toast.success(this.$t(res.info));
	  			  this.init();
	  			}, 1000);
	  		}
	  	});
	  },
	  init(){
	  		 
	  		  this.yaoqinglist = [];
	  		  apikongtou({
	  		    userid: this.token,
	  		  }).then((res) => {
				for(var i =0; i < res.info.length; i++ ){
					if(res.info[i].type == 1){
						this.yaoqinglist.push(res.info[i])
					}else if(res.info[i].type == 2){
						this.yaoqinglist2.push(res.info[i])
					}
					
				}
	  		  	
	  		});
	  },
  }
}
</script>

<style lang="scss" scoped>
.content {
	.item-Div{
	  width: 95%;
	  height: 240px;
	  background: linear-gradient(
	    180deg,
	    #0d9135 0%,
	    rgba(243, 204, 46, 0) 100%
	  );
	  border-radius: 10px;
	  display: flex;
	  margin-left: 10px;
	  margin-top: 10px;
	}
	.item-div-right{
	  width: 300px;
	  height: 100px;
	  display:table-cell;
	  text-align: left;
	  vertical-align: middle;
	  padding-top: 20px;
	}
	.item-div-center{
	  width: 60%;
	  height: 100px;
	  text-align: left;
	  margin-top: 15px;
	}
	.item-div-center-tips{
	  color: #C0C0C0;
	  margin-top: 5px;
	  font-size: 10px
	}
	.item-div-left{
	  width: 15%;
	  height: 100px;
	  display: flex;
	  justify-content: center;
	  margin-top: 10px;
	}
  .card {
    background: linear-gradient(180deg, #2a2c2e 0%, #202020 100%);
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    padding: 35px;
    .typeicon {
      width: 100%;
    }
	
    .table {
      border-radius: 13px 13px 13px 13px;
      overflow: hidden;
      .title {
        background: linear-gradient(
          180deg,
          #0d9135 0%,
          rgba(243, 204, 46, 0) 100%
        );
        .title-item {
          flex: 1;
          min-height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .body-item {
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .card-1 {
    .robot {
      width: 128px;
      height: 128px;
    }
  }
}
</style>
