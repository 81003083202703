<template>
  <layout :isShowFooter="false">
    <template #title>{{$t('团队规则')}}</template>
   <template #right></template>
    <template #content>
       <div class="card card-1 flexbox_column ml32 mr32">
        <div class="flexbox_column a-center">
          <img class="robot" src="@/assets/icon/robot.png" alt="" />
          <div class="f30 mt20 mb20">Plus500AI</div>
          <div class="mb20">Ingresos de inversión de IA de robótica</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab1.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab1.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.daily }}</div>
          </div>
        </div>
        <div class="flexbox j-end ccolor f30 mt20">※La inversión de interés compuesto es extremadamente rentable</div>
      </div>
      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Reglas de descuento por nivel de puntos</div>
          <div style="line-height: 20px">
            Puntos: las personas que compren Rob obtendrán el doble de puntos y
                         sus superiores obtendrán1tin (Aplicable a subordinados directos
                         Relati Descuentos de consumo subordinado:
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab2.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab2.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          Los usuarios con diferentes relaciones (directas e indirectas) recibirán
                     descuentos para subordinados para comprar robots basados en la proporción de
                     descuentos a nivel de agencia. Otros altos mandos también recibirán una
                     proporción correspondiente de descuentos según su propio agente
                     nivel. Descuentos por compra de robots: aquellos que tengan el nivel de agente
                     recibirá los derechos preferenciales de nivel de agente correspondiente al comprar
                     robots por su cuenta.
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Descripción de la comisión del agente directo</div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="item in tab3.title"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab3.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
          Porcentaje de agente de nivel 1 obtenido por A:8% Porcentaje de agente de nivel 2
                     obtenido por A: 3% Porcentaje de agente de nivel 3 obtenido por A: 2% Ejemplos:
                     La ganancia diaria de B, C, D es: 100 USD de recompensa de agente de nivel 1 obtenida por A
                     :8%*100=8 USD Recompensa de agente de nivel 2 obtenida por A: 3%*100=3 USD Nivel
                     Recompensa de 3 agentes obtenida por A: 2%*100=2 USD
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Descripción de la comisión del agente directo</div>
          <img class="typeicon" src="@/assets/icon/type.png" alt="" />
        </div>
        <div class="lh20 ccolor mt30">
          A lo representa a usted A lnvitar B (el subordinado de A es B) B Invitar a C (el subordinado de B
                     subordinado es C) C lnvitar D (el subordinado de C es D) A puede tener
                     múltiples subordinados (B1, B2, B3, B4, B5, B6, etc.) B (B1, B2, B3) también puede
                     tener varios subordinados (C1,c2,c3,c4,c5,c6,etc.) C... D...
        </div>
      </div>

      <div class="card card-1 flexbox_column ml32 mr32 mt20">
        <div class="flexbox_column a-center">
          <div class="f30 mb20">Descripción de la comisión del agente directo</div>
          <div style="line-height: 20px">
            A lo representa A Invitar a B(El subordinado de A es B) B Invitar a C(El subordinado de B
                         subordinado es C) C lnvitar D (el subordinado de C es D) △El nivel superior
                         se basará en la cantidad total de activación de la menor
                         level.0btener diferentes proporciones de recompensa de agente (%). AY refiérase a la
                         ingresos diarios de los subordinados para obtener recompensas de agencia de acuerdo a
                         la proporción correspondiente. △El robot debe estar en un estado activo normal
                         estado. Los ingresos de los agentes son proporcionados por Al smart de Tamer Technology
                         equipo de gestión financiera Nuro Al, y no afectará el normal
                         ingresos de los subordinados.
          </div>
        </div>
        <div class="table mt10">
          <div class="title flexbox">
            <div
              class="title-item flexbox j-center"
              v-for="(item, i) in tab4.title"
              :key="i"
            >
              {{ item }}
            </div>
          </div>
          <div
            class="flexbox"
            :style="{ background: i % 2 === 0 ? '#131313' : '#292B2D' }"
            v-for="(item, i) in tab4.data"
            :key="i"
          >
            <div class="body-item flex1 flexbox j-center">{{ item.i }}</div>
            <div class="body-item flex1">{{ item.price }}</div>
            <div class="body-item flex1">{{ item.startUp }}</div>
            <div class="body-item flex1">{{ item.total }}</div>
            <div class="body-item flex1">{{ item.totals }}</div>
          </div>
        </div>
        <div class="lh20 ccolor mt30">
         Ejemplos: La cantidad total de activación del nivel superior (A)> 30% de la
                    Cantidad total de activación del nivel inferior (B/C/D): (Ej: A>30% de B/C/D)
        </div>
      </div>
	   <div class="card card-1 flexbox_column   ">
	  <div class="flexbox_column a-center" style="width: 100%;">
	   
	    <img class="typeicon" :src="info.team_img" alt="" />
	  
	  </div>
	  </div>
    </template>
  </layout> 
</template>

<script>
	import {
	  apikongtou, apiky_buy,apiUser_mas2,
	  
	} from "@/http/api/";
export default {
  data() {
    return {
		token:"",
		info:[],
      tab1: {
        title: [
          'Robot\ntipo',
          'Robot precio',
          'Ingreso diario',
          'Ciclo de operación',
          'Beneficio neto'
        ],
        data: [
          {
            i: 'NO1',
            price: '$10',
            startUp: '$3.66',
            total: '3 días',
            daily: '$1'
          },
		  {
		    i: 'NO2',
		    price: '$50',
		    startUp: '$8.67',
		    total: '7 días',
		    daily: '$11.7'
		  },
		  {
		    i: 'NO3',
		    price: '$100',
		    startUp: '$10.0',
		    total: '15 días',
		    daily: '$50'
		  },
		  {
		    i: 'NO4',
		    price: '$500',
		    startUp: '$36.1',
		    total: '27 días',
		    daily: '$475'
		  },
          {
            i: 'NO5',
            price: '$1000',
            startUp: '$60.2',
            total: '42 días',
            daily: '$1530'
          }
        ]
      },
      tab2: {
        title: [
          'Puntos \nAccumula \nción',
          'Agente \n Nivel',
          'Subordinados\y Consumidor\en Reembolso',
          'Descuento\n Por robo \nCompra'
        ],
        data: [
          {
            i: '1000',
            price: 'LV2',
            startUp: '20%',
            total: '30%'
          },
		  {
		    i: '2000',
		    price: 'LV3',
		    startUp: '25%',
		    total: '35%',
		    },
		  {
		    i: '5000',
		    price: 'LV4',
		    startUp: '30%',
		    total: '40%',
		    },
		  {
		    i: '10000',
		    price: 'LV5',
		    startUp: '35%',
		    total: '45%',
		    },
          {
            i: '30000',
            price: 'LV6',
            startUp: '40%',
            total: '60%'
          }
        ]
      },
      tab3: {
        title: [
          'Agente\nNivel',
          'Agente\n Recompensa\n Ratio(%)',
          'Subordinado\n Diario \nBeneficio(USD)',
          'Agente\n Recompensa\n (USD)'
        ],
        data: [
          {
            i: 'Downline1',
            price: '8%',
            startUp: '$100',
            total: '$8'
          },
		  {
		    i: 'Downline2',
		    price: '3%',
		    startUp: '$100',
		    total: '$3',
		    },
          {
            i: 'Downline3',
            price: '2%',
            startUp: '$100',
            total: '$2'
          }
        ]
      },
      tab4: {
        title: [
          'Agente\nNivel',
          'Referencia0 \nbjeto',
          'A>30% of\n B/C/D',
          '20% of B/ \n C/D < A≤3 \n 0% of B/C/D',
          'A≤20% of \n B/C/D'
        ],
        data: [
          {
            i: 'Downline1',
            price: 'B',
            startUp: '8%',
            total: '5.4%',
            totals: '4%'
          },
		  {
		    i: 'Downline2',
		    price: 'C',
		    startUp: '3%',
		    total: '1.2%',
		    totals: '0.5%'
		  },
		  {
            i: 'Downline3',
            price: 'D',
            startUp: '2%',
            total: '0.8%',
            totals: '0.3%'
          }
        ]
      }
    }
  },
  created() {
     var token = localStorage.getItem("key");
     console.log("token", token);
     if (!token) {
       this.$router.replace("/login");
     }
     this.token = token;
     this.init();
  	 apiUser_mas2({
  	   userid: token
  	 }).then((res) => {
  	   // 未登录踢下线
  	   if (res.status == 10) {
  	     this.$router.replace('/login')
  	   }
  	   // this.showdown = res.user.showdown
  	   this.info = res.user
  	  
  	 })
   },
  methods: {
	  init(){
	  		 
	  		  this.yaoqinglist = [];
	  		  apikongtou({
	  		    userid: this.token,
	  		  }).then((res) => {
	  		  	this.yaoqinglist = res.info;
	  		});
	  },
  }
}
</script>

<style lang="scss" scoped>
.content {
  .card {
    background: linear-gradient(180deg, #2a2c2e 0%, #202020 100%);
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    padding: 35px;
    .typeicon {
      width: 100%;
    }
    .table {
      border-radius: 13px 13px 13px 13px;
      overflow: hidden;
      .title {
        background: linear-gradient(
          180deg,
          #0d9135 0%,
          rgba(243, 204, 46, 0) 100%
        );
        .title-item {
          flex: 1;
          min-height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .body-item {
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .card-1 {
    .robot {
      width: 128px;
      height: 128px;
    }
  }
}
</style>
